
import { defineComponent, onMounted, onUnmounted, reactive } from "vue";
import { useRouter } from "vue-router";
import constants from "@/components/constants";
import { getThanksByAuthorUsersId } from "@/components/firebaseOperations";
import { updateReceivedThanksUsersAndArticlesNotifications } from "@/components/thanksNotificationsFunc";
import { clickedProfile } from "@/components/aboutArticleFunc";

export default defineComponent({
  props: {
    usersId: {
      type: Number,
      required: true,
    },
  },
  async setup(props) {
    const router = useRouter();

    const state = reactive({
      receivedThanksUsersAndArticles: {
        docs: new Map(),
        lastDocSnapshot: null,
      },
      infiniteScrollNoResult: false,
    });

    /// receivedThanksUsersAndArticlesを更新する
    const updateThanks = async () => {
      let receivedThanks;

      if (state.receivedThanksUsersAndArticles.docs.size == 0) {
        receivedThanks = await getThanksByAuthorUsersId(
          props.usersId,
          null
        );
      } else {
        receivedThanks = await getThanksByAuthorUsersId(
          props.usersId,
          state.receivedThanksUsersAndArticles.lastDocSnapshot
        );
      }

      if (receivedThanks.docs.size !== 0) {
        // 全要素呼び出していない場合
        await updateReceivedThanksUsersAndArticlesNotifications(
          state,
          receivedThanks
        );
      } else {
        state.infiniteScrollNoResult = true;
      }
    };

    onUnmounted(() => {
      document.body.classList.remove("modal-open");
    });

    onMounted(() => {
      const modalWindow = document.getElementsByClassName(
        "modal-window"
      )[0] as HTMLElement;
      modalWindow.onscroll = async () => {
        if (
          state.receivedThanksUsersAndArticles.docs.size >=
            constants.UPDATE_BASE_COUNT &&
          !state.infiniteScrollNoResult
        ) {
          // 無限スクロールが有効状態の場合
          const thanksNotificationsChilds = document.getElementsByClassName(
            "thanks-notifications-child"
          );

          const rect = thanksNotificationsChilds[
            thanksNotificationsChilds.length - 1
          ].getBoundingClientRect();
          const isInView = 0 < rect.bottom && rect.top < window.innerHeight;
          if (isInView) {
            // 最後の要素が表示されている場合
            await updateThanks();
          }
        }
      };
    });

    await updateThanks();

    return {
      clickedProfile,
      router,
      state,
    };
  },
});
