import { getProfileByUsersId, getSingleArticleByArticlesId } from "@/components/firebaseOperations";

/// 無限スクロールについて、プロフィールドキュメントと記事ドキュメントをさらに取得して追加
/// @param {any} state - state
/// @param {any} receivedThanks - 受信したthanks通知
export async function updateReceivedThanksUsersAndArticlesNotifications(
  state: any,
  receivedThanks: any
) {
  const alreadyHaveArticles = new Map();
  const alreadyHaveProfiles = new Map();

  for (const thanks of state.receivedThanksUsersAndArticles.docs.values()) {
    // 既に保持しているプロフィールや記事のデータを、再度firebaseに読み取りに行かないようにするための処理
    alreadyHaveArticles.set(thanks.article.id, thanks.article);
    alreadyHaveProfiles.set(thanks.user.users_id, thanks.user);
  }

  for (const thanks of receivedThanks.docs.values()) {
    if (!alreadyHaveProfiles.has(thanks.users_id)) {
      // プロフィールを既に持っているかどうか
      alreadyHaveProfiles.set(
        thanks.users_id,
        await getProfileByUsersId(thanks.users_id, false)
      );
    }

    if (!alreadyHaveArticles.has(thanks.articles_id)) {
      // 記事を既に持っているかどうか
      alreadyHaveArticles.set(
        thanks.articles_id,
        (
          await getSingleArticleByArticlesId(
            thanks.articles_id
          )
        ).docs
          .entries()
          .next().value[1]
      );
    }

    state.receivedThanksUsersAndArticles.docs.set(thanks.id, {
      user: alreadyHaveProfiles.get(thanks.users_id),
      article: alreadyHaveArticles.get(thanks.articles_id),
    });
  }

  state.receivedThanksUsersAndArticles.lastDocSnapshot =
    receivedThanks.lastDocSnapshot;
}
