
import { defineComponent, reactive } from "vue";
import { onBeforeRouteUpdate, useRouter } from "vue-router";
import store from "@/store";
import { updateNotice, getSentThanks, getSingleArticleByArticlesId, getArticlesOfOneUser,  } from "@/components/firebaseOperations";
import ArticleSummary from "@/components/parts/ArticleSummary.vue";
import DialogBase from "@/components/parts/DialogBase.vue";
import { getSns } from "@/components/aboutArticleFunc";
import constants from "@/components/constants";
import InfiniteScroll from "infinite-loading-vue3";
import ThanksNotificationsDialogForProfilePage from "@/components/parts/ThanksNotificationsDialogForProfilePage.vue";
import { openModal, closeModal } from "@/components/modalFunc";

export default defineComponent({
  components: {
    ArticleSummary,
    DialogBase,
    InfiniteScroll,
    ThanksNotificationsDialogForProfilePage,
  },
  async setup() {
    const router = useRouter();

    onBeforeRouteUpdate((to) => {
      window.location.href = `${document.location.origin}/${to.params.userName}`;
    });

    const articlesText = "articles";
    const thanksText = "thanks";

    const state = reactive({
      user: {} as { [key: string]: any },
      sns: [] as { [key: string]: string }[],
      isMyself: false,
      notificationsModal: false,
      selectedSection: articlesText,
      articles: {
        docs: new Map(),
      } as { [key: string]: any },
      sentThanksArticles: {
        docs: new Map(),
      } as { [key: string]: any },
      infiniteScrollMessage: "",
      infiniteScrollNoResult: {
        myArticles: false,
        sentThanks: false,
      },
    });

    /// 編集ボタンを押した時に実行
    const edit = () => {
      router.push({
        path: "/edit-profile",
      });
    };

    /// 通知ボタンを押した時に実行
    const notification = async () => {
      await updateNotice(
        "confirmed",
        store.state.myUser.users_id
      ); // 通知を確認した事を、firestoreに保存する
      openModal(state, constants.MODAL.NOTIFICATIONS);
    };

    /// 設定ボタンを押した場合に実行
    const setting = () => {
      router.push({
        path: "/setting",
      });
    };

    /// セクションを変更する
    /// @param {string} section - セクション名
    const changeSection = async (section: string) => {
      state.selectedSection = section;

      if (state.sentThanksArticles.docs.size === 0) {
        if (section === thanksText) {
          const sentThanks = await getSentThanks(null);
          await moreAddSentThankedDocs(sentThanks);
        }
      }
    };

    /// プロフィールをクリックした時に実行
    /// @param {string} name - ユーザー名
    const clickedProfile = (name: string) => {
      router.push({
        path: `/${name}`,
      });
    };

    /// 記事をクリックした時に実行
    /// @param {string} path - 記事path_param_id
    const clickedArticle = (path: string) => {
      router.push({
        path: `/article/${path}`,
      });
    };

    /// thanksを送信した記事に関するドキュメントをさらに追加する
    /// @param {any} sentThanks - thanksを送信した記事たちのMap
    const moreAddSentThankedDocs = async (sentThanks: any) => {
      for (const thanks of sentThanks.docs.values()) {
        const article = await getSingleArticleByArticlesId(
          thanks.articles_id
        );

        const articleDoc = article.docs.entries().next().value[1];
        state.sentThanksArticles.docs.set(thanks.articles_id, articleDoc);
      }

      state.sentThanksArticles.lastDocSnapshot = sentThanks.lastDocSnapshot;
    };

    /// thanksを送信した記事の要素群に関して、最後の要素まで達した時に実行
    const sentThanksArticlesInfiniteHandler = async () => {
      if (
        state.sentThanksArticles.docs.size >= constants.UPDATE_BASE_COUNT &&
        !state.infiniteScrollNoResult.sentThanks
      ) {
        const thanks = await getSentThanks(
          state.sentThanksArticles.lastDocSnapshot
        );

        if (thanks.docs.size !== 0) {
          await moreAddSentThankedDocs(thanks);
        } else {
          state.infiniteScrollNoResult.sentThanks = true;
        }
      }
    };

    /// 自分の記事の要素群に関して、最後の要素まで達した時に実行
    const myArticlesInfiniteHandler = async () => {
      if (
        state.articles.docs.size >= constants.UPDATE_BASE_COUNT &&
        !state.infiniteScrollNoResult.myArticles
      ) {
        const articles = await getArticlesOfOneUser(
          state.user.users_id,
          state.isMyself,
          state.articles.lastDocSnapshot,
        );

        if (articles.docs.size !== 0) {
          for (const article of articles.docs.values()) {
            state.articles.docs.set(article.id, article);
          }

          state.articles.lastDocSnapshot = articles.lastDocSnapshot;
        } else {
          state.infiniteScrollNoResult.myArticles = true;
        }
      }
    };

    state.user = store.state.profileUser;
    if (Object.getOwnPropertyNames(state.user).length != 0) {
      document.title = state.user.name + " | " + constants.DEFAULT_TITLE;
      state.sns = getSns(true, state.user);
      if (Object.getOwnPropertyNames(store.state.myUser).length != 0) {
        state.isMyself = state.user.users_id == store.state.myUser.users_id;
      }

      state.articles = await getArticlesOfOneUser(
        state.user.users_id,
        state.isMyself,
        null
      );
    }

    return {
      router,
      store,
      state,
      edit,
      notification,
      setting,
      changeSection,
      clickedProfile,
      clickedArticle,
      articlesText,
      thanksText,
      myArticlesInfiniteHandler,
      sentThanksArticlesInfiniteHandler,
      closeModal,
      constants,
    };
  },
});
