
import { defineComponent } from "vue";
import Header from "@/components/parts/Header.vue";
import BottomNavigation from "@/components/parts/BottomNavigation.vue";
import ProfilePage from "@/components/pages/ProfilePage.vue";

export default defineComponent({
  components: {
    Header,
    ProfilePage,
    BottomNavigation
  },
})
